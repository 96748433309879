import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GiBoatFishing, GiBookshelf, GiChessRook, GiPokerHand, GiSwimfins } from "react-icons/gi";
import laptopImg from "../../Assets/dev-making-decisions.png";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Github from "./Github";
import Techstack from "./Techstack";
import TechstackProjects from "./TechstackProjects";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Jordan's <strong className="purple">Bio</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" style={{borderRadius: "10%" }} />
            <br />
            <br />
            <p style={{ textAlign: "center" }}>
<b className="purple">Activities</b> and <b className="purple">Games</b>  I enjoy:
<br />
<br />
            <ul>
            <Container style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="about-activity">
            <li className="about-activity">
              <GiSwimfins /> Swimming
            </li>
            <br />
            <li className="about-activity">
              <GiBoatFishing /> Fishing
            </li>
            <br />
            <li className="about-activity">
              <GiPokerHand /> Blackjack
            </li>
            <br />
            <li className="about-activity">
              <GiBookshelf /> Reading
            </li>
            <br />
            <li className="about-activity">
              <GiChessRook /> Chess
            </li>
            </Container>
          </ul></p>
          </Col>
        </Row>
        <txt className="divider">
          <strong className="purple"> Skillstack </strong> Development
        </txt>
        <strong>Coding Languages I'm Learning & Using</strong>
        <Techstack />
        <txt className="divider"><strong className="purple"> 
        Code</strong>&<strong className="purple"> Tools </strong>
        </txt>
        <strong>Code & Tools Used in my Work</strong>
        < TechstackProjects />
        <txt className="divider">
        Learning <strong className="purple"> Toolbox</strong>
        </txt>
        <strong>Programs, Apps, & Communities</strong>
        <Toolstack />
        <Github />
      </Container>
    </Container>
  );
}

export default About;

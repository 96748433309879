import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import bitsOfCode from "../../Assets/Projects/BLoC-logo.png";
import chatify from "../../Assets/Projects/journi-avatar.png";
import editor from "../../Assets/Projects/oss-logo-wide.png";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle/>
      <Container>
        <h1 className="project-heading">
          Jordan's Recent <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          A selection of projects I've worked on recently
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="journi"
              description="She's constantly learning from your questions and interactions. As you chat with her, journi gains understanding of you and your nuances. In time, she will be capable of answering more complex questions and remembering users and their preferences. journi is part of my larger 'AI-Ecosystem' project."
              ghLink="null"
              demoLink="https://journi-n17fe4fu4-gadsdencode-pro.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Big Load 'o Code 'BLoC'"
              description="BLoC is the first python code I wrote and uploaded to Github. BLoC is a python program that takes txt files/pdf files from a directory folder, converts them to embeddings with openai and upserts the embeddings to a Supabase table."
              ghLink="https://github.com/gadsdencode/BLoC"
              demoLink="https://github.com/gadsdencode/BLoC"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Overture Systems Solutions Website"
              description="My firm needed a website refresh - we were using a site that was built for a 2005 internet in 2023. At this point in my career I had minimal coding experience so I began the process of researching and reviewing the current code. It became obvious that this wasn't going to be a refresh, it would require a rebuild. So I began the process of rebuilding our entire website from its foundations. ~2 months later our new website went live. "
              ghLink="null"
              demoLink="https://www.overture-systems.com/"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
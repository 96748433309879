import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillWindows,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/jm-logo.jpg";

function Home2() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={7} className="home-about-description">
          <txt className="divider" style={{ fontSize: "2.6em" }}>
            @Gadsdencode
            </txt>
            <p className="home-about-body">
            <b className="purple">I discovered Stable Diffusion and ChatGPT</b> in early 2023 and was immediately fascinated with it and AI. I wanted Stable Diffusion locally on my laptop but didn't know where to start.<br /><br /><b className="purple">After conducting research</b> and creating a Github account, I downloaded a repo and began learning code. My <b className="purple">process</b> is simple but requires <b className="purple">tenacity</b>.<br /><br /><i><b className="purple">Find fascinating</b> software and modify until it functions or breaks.<b className="purple"> Iterate.</b></i>
              <br />
              <br />
              <br />
              <txt className="divider">Skillstack Evolution</txt>
              <br />
              <br /> I master concepts, tools, and systems <b className="purple">rapidly</b> and <b className="purple">autodidactically</b>.
              <br />
              <i>
                <br />To name a few...<br /><br />
                </i>
                
                <b className="purple">VS Code, MS365, G-Suite, Replit, Vercel, QualtricsXM, Github, MIRO, SmartSheet</b><br />
                <br />
              <b className="purple">Navigate</b> to the <b className="purple">About</b> page for my <b className="purple">comprehensive skillstack</b>.
              <br />
              <br />
              <br />
              <txt className="divider">Driving Force</txt>
              <br />
              <br />
              <b className="purple">Curiosity and a desire to create</b>&nbsp;an AI future for{" "}
                <i>
                <b className="purple">people</b> and <b className="purple">businesses</b> drives me.
              </i>
              <br />
              <br />
              <b className="purple">My projects, resume, and more</b> are all accessible via the menu tabs above.<br /><br /><b className="purple">Portfolion</b> is ready to answer any questions you may have!
            </p>
          </Col>
          <Col md={5} className="myAvatar">
          <br />
          <br />
          <br />
          <hr className="solid"></hr>
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" style={{borderRadius: "10%" }} />
            </Tilt>
            <br />
            <b className="purple">Portfolion AI<br /> Just click the Message icon<br />at bottom-right to chat with him! </b>
            <br />
            <b className="purple">He's programmed to answer questions<br /> about my skills, work experience,<br />and resume but he is capable<br />of answering much more. </b>
            <br />
            <br />
            <hr className="solid"></hr>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Socials</h1>
            <p>
              Multiple methods to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/gadsdencode"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/j-martens/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.overture-systems.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillWindows />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          
              <txt className="divider">Personal</txt>
            <br />I'm a father from<b className="purple"> Richmond, Virginia.</b>
            <br /><br />
            When I'm not coding, I enjoy Among Us and Minecraft with my daughter, reading books from authors like Cormac McCarthy, Fyodor Dostoevsky and Friedrich Nietzsche, or listening to podcasts and playing a videogame on PS5!
            <br />
            <br />
            <txt className="divider">Professional</txt>
              <br />
              I'm learning <b className="purple">coding</b>, <b className="purple">web development</b>, and <b className="purple">AI</b>.
              <br />
              <br /> 
              <b className="purple">Continuously</b> evolving my skillstack.
          </p>
         
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
